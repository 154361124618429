import BigNumber from 'bignumber.js'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import chef from '../../assets/img/bubblemaster.png'
import Button from '../../components/Button'
import Container from '../../components/Container'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'
import Balances from './components/Balances'
import { getPinSupply, getPinBurn } from '../../sushi/pin'
import { getBalanceNumber } from '../../utils/formatBalance'

const Home: React.FC = () => {
 const [pinTotalBurn, setPinTotalBurn] = useState<BigNumber>()
 const [pinTotalSupply, setPinTotalSupply] = useState<BigNumber>()

 useEffect(() => {
   async function fetchPinSupply() {
     const pinsupply = await getPinSupply()
     setPinTotalSupply(pinsupply)
   }
     fetchPinSupply()
 }, [setPinTotalSupply])

 useEffect(() => {
   async function fetchPinSupply() {
     const pinburn = await getPinBurn()
     setPinTotalBurn(pinburn)
   }
     fetchPinSupply()
 }, [setPinTotalBurn])

let theburn = null
if(pinTotalBurn !=undefined){
  theburn = getBalanceNumber(pinTotalBurn)
}

  return (
    <Page>
      <PageHeader
        icon={<img src={chef} height={120} />}
        title="BubbleMaster is Ready"
        subtitle="Stake Uniswap LP tokens to claim your very own Bubble!"
      />
      <StyledSubtitle2>
        <p>Experiment Powered By Mycryptoview</p>
      </StyledSubtitle2>
      <div>
      <StyledInfoburn>Total pin burned: {theburn}</StyledInfoburn >
     </div>
      <Container>
        <Balances />
      </Container>
      <Spacer size="lg" />
      <StyledInfo>
        🏆<b>Pro Tip</b>: BubbleExp-ETH / MCV-ETH UNI-V2 LP token pools yield 30X / 40X more per block.
        <p>🚀 Earn MCV tokens by learning and sharing knowledge about cryptos <a href="https://mycryptoview.com/register" target="_blank">register here</a></p>
      </StyledInfo>
      <Spacer size="lg" />
      <div
        style={{
          margin: '0 auto',
        }}
      >
        <Button text="🔪 See the Bubbles" to="/farms" variant="secondary" />
      </div>
    </Page>
  )
}

const StyledInfo = styled.h3`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;

  > b {
    color: ${(props) => props.theme.color.grey[700]};
  }
`

const StyledInfoburn = styled.p`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 16px;
  font-weight: 400;
`
const StyledSubtitle2 = styled.div`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 18px;
  font-weight: 400;
  margin-top: -30px;
  padding: 0;
  text-align: center;
`

export default Home
