import React from 'react'
import styled from 'styled-components'

const Nav: React.FC = () => {
  return (
    <StyledNav>
      <StyledLink
        target="_blank"
        href="https://etherscan.io/address/0xa5553e3fcf1d003a53ae9bd8e94fac2f4d5e0913"
      >
        BubbleMaster Contract
      </StyledLink>
      <StyledLink
        target="_blank"
        href="https://info.uniswap.org/pair/0xed88889d649cca3027bc624f19d55caceae231c9"
      >
        Uniswap BubbleExp-ETH
      </StyledLink>
      <StyledLink
        target="_blank"
        href="https://info.uniswap.org/pair/0x051a920a8dDb7DAA11498f1993E512Fe2E28A4cD"
      >
        Uniswap PinExp-ETH
      </StyledLink>
      <StyledLink
        target="_blank"
        href="https://t.me/bubbleswap"
      >
      Telegram
      </StyledLink>
      <StyledLink
        target="_blank"
        href="https://twitter.com/MycryptoviewMCV"
      >
      Twitter
      </StyledLink>
      <StyledLink
        target="_blank"
        href="https://github.com/AgileChiefPriest/BubbleSwap"
      >
      Github
      </StyledLink>
      <StyledLink
        target="_blank"
        href="https://www.youtube.com/channel/UC4CFekk-7fAez70KBX_F-pw?view_as=subscriber"
      >
        Youtube
      </StyledLink>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[400]};
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`

export default Nav
