import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import PinAbi from './lib/abi/pin.json'


const web3 = new Web3(Web3.givenProvider || "https://mainnet.infura.io/v3/2b67978531c647f991b01cafe3b467ee")
const contract = '0xa1d0834b4a85b5b8673044c192e13ad1ca99977b'
const pin = new web3.eth.Contract(PinAbi, contract)

export const getPinSupply = async () => {
  
  return new BigNumber(await pin.methods.totalSupply().call())
}

export const getPinBurn = async () => {
  return new BigNumber(await pin.methods.totalBurn().call())
}
