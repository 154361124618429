import BigNumber from 'bignumber.js/bignumber'


export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)


export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const addressMap = {
  uniswapFactory: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  uniswapFactoryV2: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  YFI: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  YCRV: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  UNIAmpl: '0xc5be99a02c6857f9eac67bbce58df5572498f40c',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  UNIRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  LINK: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  MKR: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  SNX: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
  COMP: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  LEND: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
  SUSHIYCRV: '0x2C7a51A357d5739C5C74Bf3C96816849d2c9F726',
}


export const contractAddresses = {
  sushi: {
    1: '0xab536d1c8fdc4025f9991499c7e2ef54186b44f5',
  },
  masterChef: {
    1: '0xa5553e3fcf1d003a53ae9bd8e94fac2f4d5e0913',
  },
  weth: {
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
}

/*
UNI-V2 LP Address on mainnet for reference
==========================================
0  USDT 0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852
1  USDC 0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc
2  DAI  0xa478c2975ab1ea89e8196811f51a7b7ade33eb11
3  sUSD 0xf80758ab42c3b07da84053fd88804bcb6baa4b5c
4  COMP 0xcffdded873554f362ac02f8fb1f02e5ada10516f
5  LEND 0xab3f9bf1d81ddb224a2014e98b238638824bcf20
6  SNX  0x43ae24960e5534731fc831386c07755a2dc33d47
7  UMA  0x88d97d199b9ed37c29d846d00d443de980832a22
8  LINK 0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974
9  BAND 0xf421c3f2e695c2d4c0765379ccace8ade4a480d9
10 AMPL 0xc5be99a02c6857f9eac67bbce58df5572498f40c
11 YFI  0x2fdbadf3c4d5a8666bc06645b8358ab803996e28
12 SUSHI 0xce84867c3c02b05dc570d0135103d3fb9cc19433
*/

export const supportedPools = [
  {
    pid: 5,
    lpAddresses: {
      1: '0xEd88889d649cCA3027bc624f19D55caCeae231C9',
    },
    tokenAddresses: {
      1: '0xab536d1c8fdc4025f9991499c7e2ef54186b44f5',
    },
    name: 'Bubble Party!',
    symbol: 'BUBBLE-ETH UNI-V2 LP',
    tokenSymbol: 'BubbleExp',
    icon: '',
   },
   {
     pid: 4,
     lpAddresses: {
       1: '0x44f3d19613fd593c564a35837cbc20c6fe99dba7',
     },
     tokenAddresses: {
       1: '0x9F033897C1957AA11Ac18428c1f9f85985196b55',
     },
     name: 'Mycryptoview.com Token',
     symbol: 'MCV-ETH UNI-V2 LP',
     tokenSymbol: 'MCV',
     icon: 'mcv',
   },
   {
     pid: 6,
     lpAddresses: {
       1: '0x051a920a8dDb7DAA11498f1993E512Fe2E28A4cD',
     },
     tokenAddresses: {
       1: '0xa1d0834b4a85b5b8673044c192e13ad1ca99977b',
     },
     name: 'Pin',
     symbol: 'PIN-ETH UNI-V2 LP',
     tokenSymbol: 'Pin',
     icon: '📌',
   },
   {
     pid: 7,
     lpAddresses: {
       1: '0xff8a3409b77a31b235131ac4b37dce012181da43',
     },
     tokenAddresses: {
       1: '0x9F033897C1957AA11Ac18428c1f9f85985196b55',
     },
     name: 'MCV Bubble',
     symbol: 'MCV-BUBBLE UNI-V2 LP',
     tokenSymbol: 'Pin',
     icon: '🐽',
   },
   {
      pid: 3,
      lpAddresses: {
        1: '0x2fdbadf3c4d5a8666bc06645b8358ab803996e28',
      },
      tokenAddresses: {
        1: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
      },
      name: 'YFI',
      symbol: 'YFI-ETH UNI-V2 LP',
      tokenSymbol: 'YFI',
      icon: '💰',
    },
    {
      pid: 2,
      lpAddresses: {
        1: '0xce84867c3c02b05dc570d0135103d3fb9cc19433',
      },
      tokenAddresses: {
        1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
      },
      name: 'Sushi',
      symbol: 'SUSHI-ETH UNI-V2 LP',
      tokenSymbol: 'Sushi',
      icon: '🍣',
    },
    {
      pid: 1,
      lpAddresses: {
        1: '0x5a265315520696299fa1ece0701c3a1ba961b888',
      },
      tokenAddresses: {
        1: '0xD04785C4d8195e4A54d9dEc3a9043872875ae9E2',
      },
      name: 'Rotten',
      symbol: 'ROT-ETH UNI-V2 LP',
      tokenSymbol: 'ROT',
      icon: '🤢',
    },
    {
    pid: 0,
    lpAddresses: {
      1: '0xd3d2e2692501a5c9ca623199d38826e513033a17',
    },
    tokenAddresses: {
      1: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    },
    name: 'UNI ETH',
    symbol: 'UNI-ETH UNI-V2 LP',
    tokenSymbol: 'UNI',
    icon: '🦄',
  },
]
