export const white = '#FFF'
export const black = '#000'

export const green = {
  500: '##00d1810',
}

export const red = {
  100: '#FFFDFE',
  200: '#ffc2a8',
  500: '#ffffff',
}

export const grey = {
  100: '#f7f4f2',
  200: '#4c004',
  300: '#e2d6cf',
  400: '#ffffff',
  500: '#ffffff',
  700: '#000000',
  600: '#fffff',
  800: '#2d1e12',
  900:'#8288d7',
  1000: '##000000'
}
